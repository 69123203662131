/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useMemo, useState } from "react";
// @mui material components
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
// import { login } from "actions/userActions";
// Image
import bgImage from "assets/images/onboarding.jpg";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDComponents/MDBox";
import MDButton from "components/MDComponents/MDButton";
import MDInput from "components/MDComponents/MDInput";
import MDTypography from "components/MDComponents/MDTypography";
// formik components
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
// Authentication layout components
import IllustrationLayout from "screens/Auth/components/IllustrationLayout";
import PropTypes from "prop-types";
// import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import * as yup from "yup";
import axios from "axios";
import countryList from "react-select-country-list";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  first_name: yup
    .string("Enter your first name")
    .min(2, "First name should be of minimum 2 characters length")
    .required("First name is required"),
  last_name: yup
    .string("Enter your last name")
    .min(2, "Last name should be of minimum 2 characters length")
    .required("Last name is required"),
  country: yup.string("Choose a country").required("Country is required"),
  openai_api_key: yup
    .string("Enter your OpenAI API")
    .required("OpenAI API is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  re_password: yup
    .string("Confirm your password")
    .required("Confirm Password is required"),
});

function SignUpScreen({ location }) {
  const countryOptions = useMemo(() => countryList().getData(), []);
  // const dispatch = useDispatch();

  // get userLogin from the redux store with useSelector
  // const userLogin = useSelector((state) => state.userLogin);
  // const { loading, error, userInfo } = userLogin;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { from } = location.state || { from: { pathname: "/" } };
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      openai_api_key: "",
      country: "",
      password: "",
      re_password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      
      const credentials = {
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        country: values.country,
        openai_api_key: values.openai_api_key,
        password: values.password,
        re_password: values.re_password,
      };
      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };
      axios
        .post("/auth/users/", credentials)
        .then((response) => {
          
          setError(false);
          setLoading(false);
          navigate(from);
        })
        .catch((e) => {
          
          if (
            e.response.data.email ==
            "custom user with this email already exists."
          ) {
            setError("User with this email already exists.");
          } else {
            // check if e.response.data has any key then access the first one other wise set error to true
            
            const keys = Object.keys(e.response.data);
            if (keys.length > 0) {
              setError(e.response.data[keys[0]]);
            } else {
              setError(true);
            }
          }
        });

      // 
      // // DISPATCH LOGIN
      // dispatch(login(values.email, values.password));
    },
  });

  // useEffect(() => {
  //   if (userInfo) {
  //     navigate(from);
  //   }
  // }, [navigate, userInfo, from]);
  return (
    <IllustrationLayout
      title="Create a new Account"
      // description="Enter your email and password to sign in"
      illustration={bgImage}
    >
      {error && (
        <Grid container justifyContent="center">
          <MDBox m={0.75} mb={5}>
            <Alert severity="error" sx={{ width: "100%" }}>
              {error}
            </Alert>
          </MDBox>
        </Grid>
      )}
      <FormikProvider value={formik}>
        <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
          <MDBox mb={2}>
            <Field
              as={MDInput}
              type="text"
              label="First Name"
              name="first_name"
              fullWidth
              value={formik.values.first_name}
              onChange={formik.handleChange}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
            />

            <MDBox mt={0.75}>
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
              >
                <ErrorMessage name="first_name" />
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox mb={2}>
            <Field
              as={MDInput}
              type="text"
              label="Last Name"
              name="last_name"
              fullWidth
              value={formik.values.last_name}
              onChange={formik.handleChange}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
            />

            <MDBox mt={0.75}>
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
              >
                <ErrorMessage name="last_name" />
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox mb={2}>
            <Field
              as={MDInput}
              type="email"
              label="Email"
              name="email"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
            <MDBox mt={0.75}>
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
              >
                <ErrorMessage name="email" />
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox mb={2}>
            <Field
              as={MDInput}
              type="text"
              label="OpenAI API Key"
              name="openai_api_key"
              fullWidth
              value={formik.values.openai_api_key}
              onChange={formik.handleChange}
              error={
                formik.touched.openai_api_key &&
                Boolean(formik.errors.openai_api_key)
              }
            />
            <MDBox mt={0.75}>
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
              >
                <ErrorMessage name="openai_api_key" />
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox mb={2}>
            <Autocomplete
              options={countryOptions}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label="Country"
                  variant="outlined"
                  name="country"
                  error={formik.touched.country && Boolean(formik.errors.role)}
                />
              )}
              fullWidth
              value={formik.values.country}
              onChange={(e, newValue) =>
                formik.setFieldValue("country", newValue.label)
              }
              onBlur={() => formik.setTouched({ country: true })}
            />
            <MDBox mt={0.75}>
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
              >
                <ErrorMessage name="country" />
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox mb={2}>
            <Field
              as={MDInput}
              type="password"
              label="Password"
              name="password"
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={
                formik.touched.password && Boolean(formik.errors.password)
              }
            />
            <MDBox mt={0.75}>
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
              >
                <ErrorMessage name="password" />
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox mb={2}>
            <Field
              as={MDInput}
              type="password"
              label="Confirm Password"
              name="re_password"
              fullWidth
              value={formik.values.re_password}
              onChange={formik.handleChange}
              error={
                formik.touched.re_password &&
                Boolean(formik.errors.re_password)
              }
            />
            <MDBox mt={0.75}>
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
              >
                <ErrorMessage name="re_password" />
              </MDTypography>
            </MDBox>
          </MDBox>

          <MDBox mt={4} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              size="large"
              fullWidth
              type="submit"
              sx={{ postion: "relative" }}
            >
              sign up
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "grey",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </MDButton>
          </MDBox>
          <MDBox mt={3} textAlign="center">
            <MDTypography
              variant="small"
              color="black"
              fontWeight="medium"
              textGradient
            >
              Already have an account?&nbsp;
            </MDTypography>
            <MDTypography
              component={Link}
              to="/sign-in"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign In
            </MDTypography>
          </MDBox>
        </MDBox>
      </FormikProvider>
    </IllustrationLayout>
  );
}

SignUpScreen.propTypes = {
  location: PropTypes.string,
};

SignUpScreen.defaultProps = {
  location: "",
};

export default SignUpScreen;
