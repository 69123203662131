//imports
import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

// confirmation dialog component
function ConfirmationDialogComponent(props) {
  const { title, message, open, handleClose, handleConfirm, confirmButtonText } = props;

  return (
    <Dialog open={open} onClose={handleClose} disablePortal>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm} color="error">
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default ConfirmationDialogComponent;
