// Material Dashboard 2 PRO React components
import MDBox from "components/MDComponents/MDBox";
import MDTypography from "components/MDComponents/MDTypography";
import MDInput from "components/MDComponents/MDInput";
import MDDropzone from "components/MDComponents/MDDropzone";
import MDButton from "components/MDComponents/MDButton";
import Footer from "components/Footer";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

// my
import { Field, FormikProvider, useFormik, ErrorMessage } from "formik";
import * as yup from "yup";
import axiosInstance from "config/axios";
import { useMemo } from "react";
import { Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

const SUPPORTED_FORMATS = ["application/pdf"];
const validationSchema = yup.object().shape({
  title: yup.string().required("This field is Required"),
  pdf_file: yup
    .mixed()
    .nullable()
    .required("File is required")
    .test(
      "FILE_FORMAT",
      "Uploaded file has unsupported format.",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    ),
});

function PaperSummarizeScreen() {
  const formik = useFormik({
    initialValues: {
      title: "",
      summary: "",
      pdf_file: null,
      price: 1,
    },
    validationSchema,
    onSubmit: (values) => {
      const url = "/api/summary/create/";
      // Use Formdata if you have Files in your form
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
      // The Content-Type is set to multipart/form-data because of the files
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      // const data = {
      //   title: formik.values.title,
      //   summary: formik.values.summary,
      //   pdf_file: formik.values.pdf_file,
      //   price: 1,
      // };
      
      let res = "";
      axiosInstance
        .post(url, formData, config)
        .then((response) => {
          res = response.data;
          
          formik.setFieldValue("summary", res);
        })
        .catch((error) => {
          
        });
    },
  });

  // useEffect(() => {
  //   // formik.setFieldValue("pdf_file", file);
  //   // 
  // }, [file]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={12}>
              <MDTypography variant="h4" fontWeight="medium" align="center">
                Summarize
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <FormikProvider value={formik}>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            component="form"
            role="form"
            onSubmit={formik.handleSubmit}
          >
            <Grid item xs={12} lg={12}>
              <Card>
                <MDBox p={3}>
                  <MDBox mt={1}>
                    <Grid item>
                      <Tooltip title="What would like to call this project" placement="top">
                        <div>
                          <Field
                            as={MDInput}
                            required
                            type="text"
                            label="Project Name"
                            fullWidth
                            name="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            error={formik.touched.title && Boolean(formik.errors.title)}
                          />
                        </div>
                      </Tooltip>
                      <MDBox mt={0.75}>
                        <MDTypography
                          component="div"
                          variant="caption"
                          color="error"
                          fontWeight="regular"
                        >
                          <ErrorMessage name="title" />
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item>
                      <Tooltip placement="top" title="Please upload a file to Summrize it">
                        <div>
                          {useMemo(
                            () => (
                              <Field
                                as={MDDropzone}
                                type="file"
                                name="pdf_file"
                                required
                                defaultValue={formik.values.pdf_file}
                                onChange={formik.handleChange}
                                options={{
                                  addRemoveLinks: true,
                                  maxFiles: 1,
                                  uploadMultiple: false,
                                  // eslint-disable-next-line object-shorthand
                                  init: function () {
                                    const count = 0;
                                    // eslint-disable-next-line react/no-this-in-sfc
                                    this.on("removedfile", (file) => {
                                      
                                      // eslint-disable-next-line react/no-this-in-sfc
                                      formik.setFieldValue("pdf_file", null);
                                    });
                                    // eslint-disable-next-line react/no-this-in-sfc
                                    this.on("addedfiles", (file) => {
                                      
                                      // eslint-disable-next-line react/no-this-in-sfc
                                      while (this.files.length > this.options.maxFiles) {
                                        // eslint-disable-next-line react/no-this-in-sfc
                                        this.removeFile(this.files[0]);
                                        
                                      }
                                      formik.setFieldValue("pdf_file", file[0]);
                                      
                                    });
                                  },
                                }}
                              />
                            ),
                            []
                          )}
                        </div>
                      </Tooltip>
                      <MDBox mt={0.75}>
                        <MDTypography
                          component="div"
                          variant="caption"
                          color="error"
                          fontWeight="regular"
                        >
                          <ErrorMessage name="pdf_file" />
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Tooltip
                        title="This field will have the summary of your paper file"
                        placement="top"
                      >
                        <div className="summaryPlace">
                          <Field
                            as={MDInput}
                            multiline
                            minRows={3}
                            type="text"
                            label="summary"
                            fullWidth
                            name="summary"
                            InputProps={{
                              readOnly: true,
                            }}
                            sx={{
                              backgroundColor: "whitesmoke",
                              marginTop: "0.5rem",
                            }}
                            value={formik.values.summary}
                            error={formik.touched.summary && Boolean(formik.errors.summary)}
                          />
                        </div>
                      </Tooltip>
                      <MDBox mt={0}>
                        <MDTypography
                          component="div"
                          variant="caption"
                          color="error"
                          fontWeight="regular"
                        >
                          <ErrorMessage name="summary" />
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    {/* <Grid item> */}
                    <MDBox display="flex" justifyContent="center" marginTop={1.5}>
                      <MDButton variant="gradient" color="myBtn" type="submit">
                        Summarize
                      </MDButton>
                    </MDBox>
                    {/* </Grid> */}
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </FormikProvider>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PaperSummarizeScreen;
