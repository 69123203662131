// imports
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
// import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Divider } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

// import propTypes
import PropTypes from "prop-types";

import brandWhite from "assets/images/ReviewGPT Logo White.png";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

// components
import ReviewRecordDetails from "../ReviewRecordDetails";
import ChatBubble from "../ChatBubble";

// constants

// hooks

// utils

// styles

function ReviewItem({ review, userInitials, handleDeleteReview }) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const createdAt = new Date(review.created_at).toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  ("ReviewItem", review);

  return (
    <Box sx={{ mb: 3 }}>
      <Card
        variant="outlined"
        sx={{
          borderRadius: "16px",
          boxShadow: "none",
          bgcolor: "#D3D9DF",
        }}
      >
        <Box p={2}>
          <Grid item align="right" mb={1}>
            <IconButton
              onClick={() => setShowDeleteConfirmation(true)}
              aria-label="Delete review"
              sx={{ p: 0, m: 0, fontSize: "22px" }}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Grid>
          <ChatBubble
            backgroundColor="background.paper"
            avatarContent={userInitials}
            text={review.context}
            details={
              <ReviewRecordDetails
                sectionType={review.section_type}
                feedbackTypes={review.feedback_types}
              />
            }
          />
        </Box>
        <Divider />

        <Box p={2}>
          <ChatBubble
            backgroundColor="grey.200"
            avatarContent={
              <img
                src={brandWhite}
                alt="Brand White"
                style={{ width: 40, height: 30 }}
              />
            }
            avatarBgColor="#5582BA"
            text={review.feedback}
            hasCopyButton={true}
          />
          <Grid
            container
            alignItems="center"
            justifyContent={isMobile ? "flex-start" : "flex-end"} // Adjust alignment based on viewport
          >
            <Grid item>
              <Typography
                variant="caption"
                color="textSecondary"
                sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}
              >
                {createdAt}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Card>

      {/* Confirmation Dialog */}
      <Dialog
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        disablePortal
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete this review?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteConfirmation(false)}>
            Cancel
          </Button>
          <Button onClick={() => handleDeleteReview(review.id)} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ReviewItem;
