import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "config/axios";
import { Box, Typography } from "@mui/material";
import ReviewHistory from "components/CustomComponents/ReviewHistory";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import * as yup from "yup";

import Tooltip from "@mui/material/Tooltip";

import MDBox from "components/MDComponents/MDBox";
import MDButton from "components/MDComponents/MDButton";
import MDInput from "components/MDComponents/MDInput";
import MDTypography from "components/MDComponents/MDTypography";
import Footer from "components/Footer";

const ViewPaper = ({ match }) => {
  const { id } = useParams();
  const [project, setProject] = useState({});
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axiosInstance.get(`/api/projects/${id}/`);
        const projectData = response.data;
        setProject(projectData);
      } catch (error) {
        console.error("Error fetching project:", error);
      }
    };

    const fetchReviews = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/projects/${id}/reviews/`
        );
        const reviewData = response.data;
        setReviews(reviewData.reviews);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchProject();
    fetchReviews();
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <ReviewHistory
        title={`Review History of Project: ${project.title}`}
        history={reviews}
        setHistory={setReviews}
      />

      <Footer />
    </DashboardLayout>
  );
};

export default ViewPaper;
