//imports
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ReviewItem from "./components/ReviewItem";
import { useEffect } from "react";
import axiosInstance from "config/axios";
import { getInitials } from "utils/userProfileHelpers";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "styles/toastStyles.css";

const ReviewHistory = ({ title, history, setHistory }) => {
  const [userInitials, setUserInitials] = useState("");

  useEffect(() => {
    async function fetchUserProfile() {
      try {
        const response = await axiosInstance.get("/api/user/profile/");
        const { first_name, last_name } = response.data;
        const userInitials = getInitials(first_name, last_name);
        setUserInitials(userInitials);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    }

    fetchUserProfile();
  }, []);

  function handleDeleteReview(id) {
    const url = `/api/reviews/${id}/`;

    axiosInstance
      .delete(url)
      .then(() => {
        ("deleted review");
        setHistory((history) => history.filter((review) => review.id !== id));

        // setShowDeleteConfirmation(false);
        toast.success("Review deleted successfully!", {
          // draggable: false,
          className: "toast-success",
          closeOnClick: false,
        });
      })
      .catch((error) => {
        console.error("Error deleting review:", error);
      });
  }
  return (
    <Box mt={4}>
      <Typography variant="h5" mb={2}>
        {title}
      </Typography>
      {history.length > 0 ? (
        <div>
          {history.map((review) => (
            <ReviewItem
              review={review}
              userInitials={userInitials}
              key={review.id}
              handleDeleteReview={handleDeleteReview}
            />
          ))}
        </div>
      ) : (
        <Typography variant="body1" color="textSecondary">
          No reviews available.
        </Typography>
      )}
      <ToastContainer />
    </Box>
  );
};

export default ReviewHistory;
