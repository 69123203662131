//imports
import React from "react";
import { useTheme } from "@mui/material/styles";
import { Avatar } from "@mui/material";
import MDAvatar from "components/MDComponents/MDAvatar";

// Compoent
function CustomAvatar(props) {
  const { avatarContent, avatarBgColor, type } = props;
  let settings = {};
  if (type === "profile") {
    settings = {
      alt: "profile-image",
      size: "xl",
      shadow: "sm",
    };
  }
  return (
    // <Avatar
    //   sx={{
    //     // if avatar bgcolor is provided then use it as bg color otherwise use the default one
    //     bgcolor: avatarBgColor || "#7D8B8D",
    //     borderRadius: "50%",
    //     width: "40px",
    //     height: "40px",
    //   }}
    // >
    //   {avatarContent}
    // </Avatar>
    <MDAvatar
      sx={{
        // if avatar bgcolor is provided then use it as bg color otherwise use the default one
        bgcolor: avatarBgColor || "#7D8B8D",
        borderRadius: "50%",
        ...(type === "profile"
          ? {
              fontSize: "2rem",
            }
          : {
              width: "40px",
              height: "40px",
            }),
      }}
      {...settings}
    >
      {avatarContent}
    </MDAvatar>
  );
}
export default CustomAvatar;
