import React, { useState, useEffect } from "react";
import history from "config/history";
import {
  CircularProgress,
  Grid,
  Card,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import axiosInstance from "config/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "styles/toastStyles.css";
import ProjectCard from "./components/ProjectCard";
import ConfirmationDialog from "components/CustomComponents/ConfirmationDialog";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Alert from "@mui/material";
import Footer from "components/Footer";
import MDBox from "components/MDComponents/MDBox";
import MDButton from "components/MDComponents/MDButton";
import MDTypography from "components/MDComponents/MDTypography";

// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles((theme) => ({
//   addButtonContainer: {
//     position: "fixed",
//     bottom: 56,
//     right: 16,
//     zIndex: 100,
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     backgroundColor: theme.palette.background.paper,
//     borderRadius: "50%",
//     boxShadow: theme.shadows[3],
//     padding: theme.spacing(1),
//   },
// }));

function PaperList() {
  const [projectsOverviews, setProjectsOverviews] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    // const accessToken = JSON.parse(localStorage.getItem("userInfo")).access;
    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${accessToken}`,
    //   },
    // };
    // const userInfo = localStorage.getItem("userInfo");
    // const token = userInfo ? JSON.parse(userInfo).access : null;
    // 
    // if (token) {
    //   // Set authentication token in request headers
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    async function getProjectsOverviews() {
      try {
        const { data } = await axiosInstance.get("/api/projects/");

        const projectsOverviews = await Promise.all(
          data.map(async (project) => {
            try {
              const reviewsResponse = await axiosInstance.get(
                `/api/projects/${project.id}/reviews/`
                // config
              );
              const reviewAbstract = reviewsResponse.data.reviews
                .reverse()
                .find((review) => review.section_type === "Abstract");

              return {
                ...project,
                context: reviewAbstract
                  ? reviewAbstract.context
                  : "Abstract not submitted",
              };
            } catch (error) {
              
              return { ...project, abstractPreview: "Error fetching review" };
            }
          })
        );

        setLoading(false);
        setProjectsOverviews(projectsOverviews.reverse());
      } catch (error) {
        
        setError(error);
      }
    }

    getProjectsOverviews();
  }, []);

  const Navigate = useNavigate();

  const handleDelete = (id) => {
    setSelectedProject(id);
    setShowDeleteConfirmation(true);
  };

  const deleteProject = async (id) => {
    try {
      await axiosInstance.delete(`/api/projects/${id}/`);
      setShowDeleteConfirmation(false);
      toast.success("Project deleted successfully", {
        className: "toast-success",
        closeOnClick: false,
      });

      setProjectsOverviews((prevProjects) =>
        prevProjects.filter((project) => project.id !== id)
      );
    } catch (error) {
      console.error("Error deleting project:", error);
      toast.error("Failed to delete project");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box my={3} px={3}>
        <Box mb={6}>
          <Typography variant="h4" fontWeight="medium" align="center">
            My Projects
          </Typography>
        </Box>
        {error ? (
           <> 
           {/* <Alert severity="error">Server Error - 500</Alert> */}
          {window.location.reload()}</>
          ) : loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="80vh"
            >
              <CircularProgress color="primary" size="5rem" />
            </Box>
          
        ) : projectsOverviews.length < 1 ? (
          <Box textAlign="center" mt={10}>
            <Typography variant="h1" color="black" mb={2}>
              You Have No Projects
            </Typography>
            <Typography variant="h6" color="black">
              Click on the "+" button to get started with your first project
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={3}>
            {projectsOverviews.map((projectOverview) => (
              <Grid item xs={12} key={projectOverview.id}>
                <ProjectCard
                  title={projectOverview.title}
                  context={projectOverview.context}
                  id={projectOverview.id}
                  handleDelete={handleDelete}
                  // setShowDeleteConfirmation={setShowDeleteConfirmation}
                />
              </Grid>
            ))}
          </Grid>
        )}
        <ConfirmationDialog
          open={showDeleteConfirmation}
          handleClose={() => setShowDeleteConfirmation(false)}
          title="Confirm Delete"
          message="Are you sure you want to delete this project?"
          confirmButtonText="Delete"
          handleConfirm={() => deleteProject(selectedProject)}
        />
      </Box>

      <Tooltip title="Add a new Project" arrow placement="left">
        <Box
          position="fixed"
          top={128}
          right={80}
          zIndex={100}
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="background.paper"
          borderRadius="50%"
          boxShadow={3}
          p={1}
        >
          <AddIcon
            fontSize="large"
            color="#344767"
            onClick={() => Navigate("/projects/create")}
            style={{ cursor: "pointer" }}
          />
        </Box>
      </Tooltip>
      <ToastContainer />
      <Footer />
    </DashboardLayout>
  );
}

export default PaperList;
