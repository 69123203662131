import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

function ProjectCard({ title, context, id, handleDelete }) {
  const navigate = useNavigate();

  const handleDeleteIconClick = (event) => {
    event.stopPropagation();
    handleDelete(id);
  };

  return (
    <Card
      sx={{
        // minWidth: 300,
        backgroundColor: "whitesmoke",
        "&:hover": {
          transform: "scale(1.05)",
          boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.5)",
          cursor: "pointer",
        },
        height: "150px",
        display: "flex",
        flexDirection: "column",
      }}
      onClick={() => navigate(`/paper/${id}`)}
    >
      <CardContent sx={{ flex: 1 }}>
        <Typography variant="h5" component="div" sx={{ marginBottom: "1rem" }}>
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxHeight: "100%",
            lineHeight: "1.2em",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 4,
          }}
        >
          {context}
        </Typography>
      </CardContent>
      <IconButton
        aria-label="delete"
        sx={{ position: "absolute", top: 8, right: 8 }}
        onClick={handleDeleteIconClick}
      >
        <DeleteIcon />
      </IconButton>
    </Card>
  );
}

export default ProjectCard;
