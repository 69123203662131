import axios from "axios";
import history from "./history";

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    // // Retrieve CSRF token from cookie
    // const csrftoken = document.cookie
    //   .split("; ")
    //   .find((row) => row.startsWith("csrftoken"))
    //   .split("=")[1];

    // // Set CSRF token in request headers
    // config.headers["X-CSRFToken"] = csrftoken;

    // Retrieve authentication token from local storage
    const userInfo = localStorage.getItem("userInfo");
    const token = userInfo ? JSON.parse(userInfo).access : null;
    
    if (token) {
      // Set authentication token in request headers
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/api/users/login/" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        try {
          localStorage.removeItem("userInfo");
          history.push("/sign-in");
        } catch (error) {
          
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
