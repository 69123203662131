/* eslint-disable */
// @mui material components
import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import "@pixi/graphics-extras";
// import brandWhite from "assets/images/light-pen-and-paper.png";
import brandWhite from "assets/images/ReviewGPT Logo White.png";
// Material Dashboard 2 React themes
import theme from "assets/theme";
import Sidenav from "components/Sidenav";
import { useMaterialUIController } from "context";
// react-router components
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "screens/routes/privateRoute";
// import PrivateRoute from "routes/privateRoute";
import sideNavRoutes from "sideNav.routes";
// import DashboardScreen from "screens/Dashboard";
import SignInScreen from "screens/Auth/sign-in";
import SignUpScreen from "screens/Auth/sign-up";
// Auth screens
import ForgotPasswordScreen from "screens/Auth/forgot-password";
import ResetPasswordScreen from "screens/Auth/reset-password";
import EditPasswordScreen from "screens/Users/update-user-password";
import UserProfileScreen from "screens/Users/user-profile";
import PaperAddScreen from "screens/Papers/new-paper";
import PaperSummarizeScreen from "screens/Papers/summarize";
import PaperList from "screens/Papers/paper-list";
import ViewPaper from "screens/Papers/view-paper";
import { injectStyle } from "react-toastify/dist/inject-style";
// import { ErrorBoundary } from "react-error-boundary";

// import Wallet from "screens/Profile/wallet";

const ErrorComponent = () => {
  return <h1>Something went wrong</h1>;
};

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: { message: "", stack: "" },
    info: { componentStack: "" },
  };

  static getDerivedStateFromError = (error) => {
    return { hasError: true };
  };

  componentDidCatch = (error, info) => {
    this.setState({ error, info });
  };

  render() {
    const { hasError, error, info } = this.state;
    const { children } = this.props;

    return hasError ? <ErrorComponent /> : children;
  }
}

function App() {
  injectStyle();
  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin;
  // const sidenavColor = "primary";

  const [controller] = useMaterialUIController();
  const { layout } = controller;
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    // if (miniSidenav && !onMouseEnter) {
    //   setMiniSidenav(dispatch, false);
    //   setOnMouseEnter(true);
    // }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    // if (onMouseEnter) {
    //   setMiniSidenav(dispatch, true);
    //   setOnMouseEnter(false);
    // }
  };

  // const getRoutes = (allRoutes) =>
  //   allRoutes
  //     .find((route) => route.layout === "dashboard")
  //     .map((route) => <AppRoute path={route.path} component={route.component} />);
  function logErrorToService(error, info) {
    // Use your preferred error logging service
    console.error("Caught an error:", error, info);
  }
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <Sidenav
            color="primary"
            brand={brandWhite}
            // brandName="RIOTU Helper"
            routes={sideNavRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        )}
        <Routes>
          <Route path="/sign-in" element={<SignInScreen />} />
          <Route path="/sign-up" element={<SignUpScreen />} />
          <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
          <Route
            path="/password/reset/confirm/:uid/:token"
            element={<ResetPasswordScreen />}
          />

          <Route element={<PrivateRoute />}>
            <Route path="/profile" element={<UserProfileScreen />} />
            <Route path="/projects/create" element={<PaperAddScreen />} />
            {/* <Route path="/summaries/create" element={<PaperSummarizeScreen />} /> */}
            <Route path="/projects" element={<PaperList />} />
            <Route path="/paper/:id" element={<ViewPaper />} />
            <Route path="/edit-password" element={<EditPasswordScreen />} />
          </Route>

          <Route path="*" element={<Navigate to="/projects  " />} />
        </Routes>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
// class ErrorBoundary extends Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   static getDerivedStateFromError(error) {
//     // Update state to indicate an error has occurred
//     return { hasError: true };
//   }

//   componentDidCatch(error, errorInfo) {
//     // Log the error to an error reporting service
//     console.error(error, errorInfo);
//   }

//   handleRefresh = () => {
//     // Refresh the page
//     window.location.reload();
//   };

//   render() {
//     if (this.state.hasError) {
//       return (
//         <div>
//           <h1>Oops! Something went wrong.</h1>
//           <p>An unexpected error occurred.</p>
//           <button onClick={this.handleRefresh}>Refresh</button>
//         </div>
//       );
//     }

//     // Render the wrapped components if no error occurred
//     return this.props.children;
//   }
// }
