// MOHANAD: Defining some Constants to be used else where
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_EMAIL_UPDATED = "USER_EMAIL_UPDATE_REQUEST";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";
export const USER_DELETE_RESET = "USER_DELETE_RESET";

// User Roles
export const USER_ROLE_ADMIN = "ADMIN";
export const USER_ROLE_OPERATOR = "OPERATOR";
export const USER_ROLE_MANAGER = "MANAGER";
export const USER_ROLE_COFFEE_OPERATOR = "COFFEE_OPERATOR";
