/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
// Image
import bgImage from "assets/images/illustrations/bg-image.jpeg";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDComponents/MDBox";
import MDButton from "components/MDComponents/MDButton";
import MDInput from "components/MDComponents/MDInput";
import MDTypography from "components/MDComponents/MDTypography";
import axios from "axios";
// formik components
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
// Authentication layout components
import IllustrationLayout from "screens/Auth/components/IllustrationLayout";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import * as yup from "yup";

const validationSchema = yup.object({
  /* eslint-disable  */
  new_password: yup
    .string("Enter your new password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  re_new_password: yup
    .string("Retype your new password")
    .required("Required")
    .oneOf([yup.ref("new_password"), null], "Passwords don't match!"),
  /* eslint-enable  */
});

function ForgotPasswordScreen({ location }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  // get userLogin from the redux store with useSelector
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { from } = location.state || { from: { pathname: "/" } };
  const navigate = useNavigate();

  const { uid, token } = useParams();

  const formik = useFormik({
    initialValues: {
      new_password: "",
      re_new_password: "",
    },

    validationSchema,
    onSubmit: (values) => {
      
      /* eslint-disable  */
      const { new_password, re_new_password } = values;
      const resetPassowrd = async () => {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

          setLoading(true);

          const { data } = await axios.post(
            `/password/reset/confirm`,
            { new_password, re_new_password, uid, token },
            config
          );

          
          setLoading(false);
          setSuccess(true);
        } catch (err) {
          
          setError("Something went wrong!");
          setLoading(false);
        }
      };

      resetPassowrd();
      /* eslint-enable  */
    },
  });

  useEffect(() => {
    if (userInfo) {
      navigate(from);
    }
  }, [navigate, userInfo, from]);

  return (
    <IllustrationLayout
      title="Reset Password"
      // description="Enter new password and"
      illustration={bgImage}
    >
      {error && (
        <Grid container justifyContent="center">
          <MDBox m={0.75} mb={5}>
            <Alert severity="error" sx={{ width: "100%" }}>
              {error}
            </Alert>
          </MDBox>
        </Grid>
      )}
      {success && (
        <Grid container justifyContent="center">
          <MDBox m={0.75} mb={5}>
            <Alert severity="success" sx={{ width: "100%" }}>
              Your password has been changed{" "}
              <MDBox mt={3} textAlign="center">
                <MDTypography
                  component={Link}
                  to="/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign in
                </MDTypography>
              </MDBox>
            </Alert>
          </MDBox>
        </Grid>
      )}
      <FormikProvider value={formik}>
        <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
          <MDBox mb={2}>
            <Field
              as={MDInput}
              type="password"
              label="New Password"
              name="new_password"
              fullWidth
              value={formik.values.new_password}
              onChange={formik.handleChange}
              error={formik.touched.new_password && Boolean(formik.errors.new_password)}
            />
            <MDBox mt={0.75}>
              <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                <ErrorMessage name="new_password" />
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox mb={2}>
            <Field
              as={MDInput}
              type="password"
              label="Confirm Password"
              name="re_new_password"
              fullWidth
              value={formik.values.re_new_password}
              onChange={formik.handleChange}
              error={formik.touched.re_new_password && Boolean(formik.errors.re_new_password)}
            />
            <MDBox mt={0.75}>
              <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                <ErrorMessage name="re_new_password" />
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton
              disabled={loading}
              variant="gradient"
              color="info"
              size="large"
              fullWidth
              type="submit"
              sx={{ postion: "relative" }}
            >
              Reset Password
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "grey",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </MDButton>
          </MDBox>
        </MDBox>
      </FormikProvider>
    </IllustrationLayout>
  );
}

ForgotPasswordScreen.propTypes = {
  location: PropTypes.string,
};

ForgotPasswordScreen.defaultProps = {
  location: "",
};

export default ForgotPasswordScreen;
