/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDComponents/MDBox";
import MDButton from "components/MDComponents/MDButton";
import MDInput from "components/MDComponents/MDInput";
import MDTypography from "components/MDComponents/MDTypography";
import Footer from "components/Footer";
// Material Dashboard 2 PRO React examples

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import * as yup from "yup";

import "./style.css";
import axiosInstance from "config/axios";
import { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

import AddIcon from "@mui/icons-material/Add";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import "styles/toastStyles.css";

import ReviewHistory from "components/CustomComponents/ReviewHistory";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import "styles/Dialog.module.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const feedbackTypes = [
  { label: "Score", value: "score" },
  { label: "Technical Score", value: "technical_score" },
  {
    label: "Limitations",
    value: "limitations",
  },
  {
    label: "Technical Quality Score",
    value: "technical_quality_score",
  },
  { label: "Technical Strengths", value: "technical_strengths" },
  { label: "Technical Weaknesses", value: "technical_weaknesses" },
  {
    label: "Typos and Language Errors",
    value: "typos_and_language_errors",
  },
  { label: "Paper Discipline", value: "paper_discipline" },
];
const validationSchema = yup.object().shape({
  title: yup.string().required("This field is Required"),
  context: yup.string().required("This field is Required"),
  feedback_types: yup
    .array()
    .of(yup.string())
    .required("This field is Required"),
  section_type: yup.string().required("This field is Required"),
  feedback_length: yup.string().required("This field is Required"),
  quality: yup.string().required("This field is Required"),
});

function PaperAddScreen() {
  // let loading = false;
  const [loading, setLoading] = useState(false);
  const [projectID, setProjectID] = useState(null);
  const [history, setHistory] = useState([]);

  const [
    showCreateNewProjectConfirmation,
    setShowCreateNewProjectConfirmation,
  ] = useState(false);

  // const [errorMessage, setErrorMessage] = useState("");

  
  let costPerToken = 0.01;
  const formik = useFormik({
    initialValues: {
      title: "",
      context: "",
      feedback: "Submit your paper to Review it!",
      feedback_types: [],
      section_type: "Abstract",
      feedback_length: "short",
      quality: "premium",
      price: `$${0}`,
      balance: 0,
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  function handleSubmit() {
    setLoading(true);

    const feedbackTypesObject = getFeedbackTypesObject();

    const { min, max } = getFeedbackLengthLimits();

    const data = {
      title: formik.values.title,
      context: formik.values.context,
      feedback: "",
      feedback_types: feedbackTypesObject,
      section_type: formik.values.section_type,
      feedback_length: {
        min: min,
        max: max,
      },
      quality: formik.values.quality,
    };
    const handleCreateReviewError = (error) => {
      
      setLoading(false);

      if (
        error.response &&
        error.response.status === 429 &&
        error.response.data.error === "RateLimitError"
      ) {
        
        const errorMessage = error.response.data.message;
        const errorDetail = error.response.data.detail;
        toast.error(
          <div style={{ whiteSpace: "pre-line" }}>
            OpenAI Error:{" "}
            {errorDetail.length > 100 ? errorMessage : errorDetail}
          </div>,
          {
            autoClose: 10000,
            className: "toast-error",
            closeOnClick: false,
          }
        ); // Display an error message
        // Display user-friendly message to the user
      } else if (
        error.response &&
        error.response.status === 502 &&
        error.response.data.error === "APIConnectionError"
      ) {
        
        const errorMessage = error.response.data.message;
        const errorDetail = error.response.data.detail;
        // toast.error(
        //   "Error communicating with OpenAI. Please try again later. If the problem persists, contact help.openai.com.",
        //   {
        //     autoClose: 10000,
        //     className: "toast-error",
        //     closeOnClick: false,
        //   }
        // );
        toast.error(
          <div style={{ whiteSpace: "pre-line" }}>
            OpenAI Error:{" "}
            {errorDetail.length > 100 ? errorMessage : errorDetail}
          </div>,
          {
            autoClose: 10000,
            className: "toast-error",
            closeOnClick: false,
          }
        );
      } else if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error === "InvalidRequestError" &&
        error.response.data.open_ai_error_code === "context_length_exceeded"
      ) {
        const errorMessage = error.response.data.message;
        const errorDetail = error.response.data.detail;
        toast.error(
          <div style={{ whiteSpace: "pre-line" }}>
            OpenAI Error:{" "}
            {errorDetail.length > 100 ? errorMessage : errorDetail}
          </div>,
          {
            autoClose: 10000,
            className: "toast-error",
            closeOnClick: false,
          }
        );
      } else if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error === "AuthenticationError"
      ) {
        const errorMessage = error.response.data.message;
        const errorDetail = error.response.data.detail;
        toast.error(
          <div style={{ whiteSpace: "pre-line" }}>
            OpenAI Error:{" "}
            {errorDetail === "<empty message>" || errorDetail.length > 100
              ? errorMessage
              : errorDetail}
          </div>,
          {
            autoClose: 10000,
            className: "toast-error",
            closeOnClick: false,
          }
        );
      } else {
        toast.error(
          <div style={{ whiteSpace: "pre-line" }}>An error occurred.</div>,
          {
            autoClose: 10000,
            className: "toast-error",
            closeOnClick: false,
          }
        );
      }
    };
    if (projectID === null) {
      createProject(data)
        .then((createdProject) => {
          setProjectID(createdProject.project_id);
          return createReview(createdProject.project_id, data);
        })
        .then(handleCreateReview)
        .catch(handleCreateReviewError);
    } else {
      createReview(projectID, data)
        .then(handleCreateReview)
        .catch(handleCreateReviewError);
    }
  }
  function handleCreateReview(createdReview) {
    formik.setFieldValue("feedback", createdReview.feedback);
    setLoading(false);

    toast.success("Review created successfully", {
      // draggable: false,
      className: "toast-success",
      closeOnClick: false,
    });
    updateHistory(createdReview);
    toast.info("Review added to history", {
      // draggable: false,
      className: "toast-info",
      closeOnClick: false,
    });
  }
  function getFeedbackTypesObject() {
    return formik.values.feedback_types.reduce((obj, feedback) => {
      const { value } = feedbackTypes.find((o) => o.label === feedback);
      obj[value] = true;
      return obj;
    }, {});
  }

  function getFeedbackLengthLimits() {
    let min = 0;
    let max = null;

    const feedbackLength = formik.values.feedback_length;
    
    if (feedbackLength === "short") {
      min = 100;
      max = 150;
    } else if (feedbackLength === "medium") {
      min = 150;
      max = 250;
    } else if (feedbackLength === "long") {
      min = 250;
      max = 500;
    } else if (feedbackLength === "extra long") {
      min = 500;
      // No maximum limit for "extra" feedback length
    }

    return { min, max };
  }

  function createProject(data) {
    return axiosInstance.post("/api/projects/", data).then((response) => {
      return response.data;
    });
  }

  function createReview(projectId, data) {
    const url = `/api/projects/${projectId}/reviews/`;
    return axiosInstance.post(url, data).then((response) => {
      return response.data;
    });
  }

  function updateHistory(createdReview) {
    setHistory((prevHistory) => [createdReview, ...prevHistory]);
  }

  // Temporary turned off for now
  // useEffect(() => {
  //   let res = 0;
  //   axiosInstance
  //     .get("/api/user/balance/")
  //     .then((response) => {
  //       res = response.data;
  //       formik.setFieldValue("balance", res);
  //       
  //     })
  //     .catch((error) => {
  //       
  //     });
  // }, []);

  useEffect(() => {
    let estimatedPrice = 0;
    switch (formik.values.quality) {
      case "fair":
        costPerToken = 0.001;
        break;
      case "medium":
        costPerToken = 0.002;
        break;
      case "high":
        costPerToken = 0.003;
        break;
      case "premium":
        costPerToken = 0.004;
        break;
      default:
        break;
    }
    switch (formik.values.feedback_length) {
      case "short, between 100-abstract150 words":
        estimatedPrice = 300 * costPerToken;
        break;
      case "medium, between 150-250 words":
        estimatedPrice = 500 * costPerToken;
        break;
      case "long, between 250-500 words":
        estimatedPrice = 1000 * costPerToken;
        break;
      case "extra long, more than 500 words":
        estimatedPrice = 1500 * costPerToken;
        break;

      default:
        break;
    }
    formik.setFieldValue("price", `$${estimatedPrice}`);
  }, [formik.values.quality, formik.values.feedback_length]);
  // const Navigate = useNavigate();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="flex-end">
        <Tooltip title="Add a new Project" arrow placement="top">
          <MDButton
            disabled={projectID === null}
            variant="gradient"
            color="myBtn"
            // onClick={() => Navigate("/projects/create")}
            onClick={() => setShowCreateNewProjectConfirmation(true)}
          >
            <AddIcon /> New Project
          </MDButton>
        </Tooltip>
      </MDBox>
      <MDBox my={3}>
        <MDBox mb={6}>
          {/* THIS IS THE TITLE; Dr.Anis Said Remove it */}
          {/* <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={12}>
              <MDTypography variant="h4" fontWeight="medium" align="center">
                New Paper
              </MDTypography>
            </Grid>
          </Grid> */}
        </MDBox>
        <FormikProvider value={formik}>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            component="form"
            role="form"
            onSubmit={formik.handleSubmit}
          >
            <Grid item xs={12} lg={12}>
              <Card>
                <MDBox p={3}>
                  <MDBox mt={1}>
                    <Grid container spacing={3} justifyContent="center">
                      {/* Select option start */}
                      <Grid item xs={12} sm={12}>
                        <Tooltip
                          title="What is the Title of your Paper"
                          placement="top"
                        >
                          <div>
                            <Field
                              as={MDInput}
                              required
                              type="text"
                              label="Project Title"
                              fullWidth
                              name="title"
                              value={formik.values.title}
                              onChange={formik.handleChange}
                              disabled={projectID !== null}
                              error={
                                formik.touched.title &&
                                Boolean(formik.errors.title)
                              }
                            />
                          </div>
                        </Tooltip>
                        <MDBox mt={0.75}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            <ErrorMessage name="title" />
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={4} lg={4}>
                        <Box sx={{ minWidth: 100 }}>
                          <FormControl fullWidth>
                            <InputLabel
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Section Type
                            </InputLabel>
                            <NativeSelect
                              defaultValue={formik.values.section_type}
                              onChange={formik.handleChange}
                              inputProps={{
                                name: "section_type",
                                id: "uncontrolled-native",
                              }}
                            >
                              <option value="Abstract">Abstract</option>
                              <option value="Introduction">
                                Introduction
                              </option>
                              <option value="Related Work">
                                Related Work
                              </option>
                              <option value="Methodology">Methodology</option>
                              <option value="Experimental Evaluation">
                                Experimental Evaluation
                              </option>
                              <option value="Simulation Evaluation">
                                Simulation Evaluation
                              </option>
                              <option value="Conclusion">Conclusion</option>
                            </NativeSelect>
                          </FormControl>
                          <MDBox mt={0}>
                            <MDTypography
                              component="div"
                              variant="caption"
                              color="error"
                              fontWeight="regular"
                            >
                              <ErrorMessage name="section_type" />
                            </MDTypography>
                          </MDBox>
                        </Box>
                      </Grid>
                      <Grid item xs={4} lg={4}>
                        <Box sx={{ minWidth: 100 }}>
                          <FormControl fullWidth>
                            <InputLabel
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Feedback Length
                            </InputLabel>
                            <NativeSelect
                              defaultValue={formik.values.feedback_length}
                              onChange={formik.handleChange}
                              inputProps={{
                                name: "feedback_length",
                                id: "uncontrolled-native",
                              }}
                            >
                              <option value="short">
                                Short (100-150) words
                              </option>
                              <option value="medium">
                                Medium (150-250) words
                              </option>
                              <option value="long">
                                Long (250-500) words
                              </option>
                              <option value="extra long">
                                Extra Long (+500) words
                              </option>
                            </NativeSelect>
                          </FormControl>
                          <MDBox mt={0}>
                            <MDTypography
                              component="div"
                              variant="caption"
                              color="error"
                              fontWeight="regular"
                            >
                              <ErrorMessage name="feedback_length" />
                            </MDTypography>
                          </MDBox>
                        </Box>
                      </Grid>
                      <Grid item xs={4} lg={4}>
                        <Box sx={{ minWidth: 100 }}>
                          <FormControl fullWidth>
                            <InputLabel
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Quality
                            </InputLabel>
                            <NativeSelect
                              disabled
                              defaultValue={formik.values.quality}
                              onChange={formik.handleChange}
                              variant="standard"
                              inputProps={{
                                name: "quality",
                                id: "uncontrolled-native",
                              }}
                            >
                              <option value="fair">Fair</option>
                              <option value="medium">Medium</option>
                              <option value="high">High</option>
                              <option value="premium">GPT 3.5</option>
                            </NativeSelect>
                          </FormControl>
                          <MDBox mt={0}>
                            <MDTypography
                              component="div"
                              variant="caption"
                              color="error"
                              fontWeight="regular"
                            >
                              <ErrorMessage name="quality" />
                            </MDTypography>
                          </MDBox>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Box sx={{ minWidth: 100, marginBottom: "1rem" }}>
                          <FormControl fullWidth>
                            <InputLabel
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Feedback Types
                            </InputLabel>
                            <Select
                              variant="standard"
                              multiple
                              value={formik.values.feedback_types}
                              onChange={formik.handleChange}
                              // input={<MDInput />}
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={MenuProps}
                              inputProps={{
                                name: "feedback_types",
                                id: "uncontrolled-native",
                              }}
                            >
                              {feedbackTypes.map((feedbackType) => (
                                <MenuItem
                                  key={feedbackType.label}
                                  value={feedbackType.label}
                                >
                                  <Checkbox
                                    checked={formik.values.feedback_types.includes(
                                      feedbackType.label
                                    )}
                                  />
                                  <ListItemText primary={feedbackType.label} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <MDBox mt={0}>
                            <MDTypography
                              component="div"
                              variant="caption"
                              color="error"
                              fontWeight="regular"
                            >
                              <ErrorMessage name="feedback_types" />
                            </MDTypography>
                          </MDBox>
                        </Box>
                      </Grid>
                      {/* Select option end */}

                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={2} marginBottom={1}>
                          {/* <Grid item xs={6} sm={6}>
                            <Tooltip title="Estimation of the Price" placement="top">
                              <div>
                                <Field
                                  as={MDInput}
                                  type="text"
                                  label="Price"
                                  fullWidth
                                  disabled
                                  name="price"
                                  value={formik.values.price}
                                  onChange={formik.handleChange}
                                />
                              </div>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={6} sm={6}>
                            <Tooltip title="Your Wallet Balance" placement="top">
                              <div>
                                <Field
                                  as={MDInput}
                                  type="text"
                                  label="Balance"
                                  fullWidth
                                  disabled
                                  name="balance"
                                  value={`$${69}`}
                                  onChange={formik.handleChange}
                                />
                              </div>
                            </Tooltip>
                          </Grid> */}

                          <Grid item xs={12} sm={12}>
                            <Tooltip
                              title="Please Write your paper's Abstract here in order to review it"
                              placement="top"
                            >
                              <div>
                                <Field
                                  as={MDInput}
                                  required
                                  type="text"
                                  label={`${formik.values.section_type} - Context`}
                                  fullWidth
                                  multiline
                                  minRows={7}
                                  name="context"
                                  value={formik.values.abstract}
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched.abstract &&
                                    Boolean(formik.errors.abstract)
                                  }
                                />
                              </div>
                            </Tooltip>
                            <MDBox mt={0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              >
                                <ErrorMessage name="abstract" />
                              </MDTypography>
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={12}>
                            <Grid item xs={3} lg={3}>
                              <MDBox
                                display="flex"
                                justifyContent="flex-start"
                                marginBottom={1.5}
                              >
                                <MDButton
                                  variant="gradient"
                                  color="myBtn"
                                  type="submit"
                                >
                                  Review!
                                </MDButton>
                              </MDBox>
                            </Grid>
                            <Backdrop
                              sx={{
                                color: "#fff",
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                              }}
                              open={loading}
                            >
                              <CircularProgress color="inherit" />
                            </Backdrop>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Tooltip
                              title="This field will have the Feedback on your paper, submit your abstract to review it."
                              placement="top"
                            >
                              <div className="feedbackPlace">
                                <Field
                                  as={MDInput}
                                  multiline
                                  minRows={3}
                                  type="text"
                                  label="Feedback"
                                  fullWidth
                                  name="feedback"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  sx={{
                                    backgroundColor: "whitesmoke",
                                  }}
                                  value={formik.values.feedback}
                                  error={
                                    formik.touched.feedback &&
                                    Boolean(formik.errors.feedback)
                                  }
                                />
                                {formik.touched.feedback &&
                                  Boolean(formik.errors.feedback) && (
                                    <MDBox mt={0}>
                                      <MDTypography
                                        component="div"
                                        variant="caption"
                                        color="error"
                                        fontWeight="regular"
                                      >
                                        <ErrorMessage name="feedback" />
                                      </MDTypography>
                                    </MDBox>
                                  )}
                              </div>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </FormikProvider>

        <ReviewHistory
          title="Review History"
          history={history}
          setHistory={setHistory}
        />
      </MDBox>
      {/* <ToastContainer /> */}
      <Dialog
        open={showCreateNewProjectConfirmation}
        onClose={() => setShowCreateNewProjectConfirmation(false)}
        disablePortal
      >
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to create a new project?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowCreateNewProjectConfirmation(false)}>
            Cancel
          </Button>
          <Button onClick={() => window.location.reload()} color="error">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default PaperAddScreen;

// export default ReviewRecordDetails;

// Review History Functional Component
