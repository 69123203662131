/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDComponents/MDBox";
import MDTypography from "components/MDComponents/MDTypography";

// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import {useState} from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import MDInput from "components/MDComponents/MDInput";
import ConfirmationDialogComponent from "components/CustomComponents/ConfirmationDialog";
import {Backdrop} from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress";
// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles({
//   text: {
//     variant: "button",
//     fontWeight: "regular",
//     color: "text",
//   },
// });
const inputStyles = {
  // variant: "button",
  // fontWeight: "regular",
  // color: "text",
  // fontSize: "14px",
  // padding: "0",
  // WebkitAlignItems: "stretch" ,
  // paddingLeft: '0.5em', // Adjust the spacing value as needed
  // letterSpacing: '0.5px',
  
  

};
// .css-15e8ec1-MuiInputBase-input-MuiInput-input.Mui-disabled
const CustomDisableInput = styled(TextField)(() => ({
  ".MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#7b809a",
    color: "#7b809a",
    opacity: "1",

  }
}));
function ProfileInfoCard({
  title,
  description,
  info,
  social,
  action,
  shadow,
  updateUserProfile,
}) {
  const labels = [];
  const values = [];
  const { socialMediaColors } = colors;
  const { size } = typography;
  const [editMode, setEditMode] = useState(false);
  const [editedAPIKey, setEditedAPIKey] = useState("");
  const [editedProfile, setEditedProfile] = useState(null);
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);


  const handleEditClick = () => {
    setEditMode(true);
    setEditedProfile({openai_api_key: ""}); // Set the initial value to the existing API Key
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setEditedProfile(null); // Reset the edited value
  };

  const handleSaveClick = () => {
    // Make the API request with the edited value using the provided method from the parent component
    // Pass the editedAPIKey value to the parent component via a callback
    // Example: onSave(editedAPIKey);
    setShowUpdateConfirmation(true);
    // setEditMode(false);
  };
  
  const handleUpdateProfileConfirm = async() => {
    setShowUpdateConfirmation(false);
    //start loading
    setLoading(true);
    //make api call to update profile
    await updateUserProfile(editedProfile);


    //stop loading
    setLoading(false);

    //fix format of openAI API key

    setEditMode(false);
    //update profile
  }
  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match("openai_api_key")) {
      labels.push("OpenAI API Key");
    } else if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(
        uppercaseLetter,
        ` ${uppercaseLetter.toLowerCase()}`
      );

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array



  Object.values(info).forEach((el) => values.push(el));


  const disabledInputStyles = {
    color: "#999",
    backgroundColor: "transparent",
    cursor: "not-allowed",
  };

  // Render the card info items
  const renderItems = labels.map((label, key) => {
    if (label === "OpenAI API Key") {
      return (
        <MDBox key={label} display="flex" alignItems="center" 
        // py={1} pr={2}
        >
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {label}: &nbsp;
          </MDTypography>
          <CustomDisableInput
            onChange={(e) => setEditedProfile({...editedProfile, [Object.keys(info)[key]]: e.target.value})}
            variant="standard"
            size="small"
            type="text"
            // placeholder="Enter your new openAI API key"
            value={editMode ? editedProfile[Object.keys(info)[key]] : info.openai_api_key}
            InputProps={{ disableUnderline: !editMode }}
            disabled={!editMode}
          />
          {editMode ? (
            <>
              <IconButton onClick={handleSaveClick}>
                <Icon>done</Icon>
              </IconButton>
              <IconButton onClick={handleCancelClick}>
                <Icon>clear</Icon>
              </IconButton>
            </>
          ) : (
            <IconButton onClick={handleEditClick}>
              <Icon>create</Icon>
            </IconButton>
          )}
        </MDBox>
      );
    }
    
  
    return (
      
      <MDBox key={label}
       display="flex" py={1} pr={2}
       >
        <MDTypography
          variant="button"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}: &nbsp;
        </MDTypography>
        <MDTypography variant="button" fontWeight="regular" color="text">
          &nbsp;{values[key]}
        </MDTypography>
      </MDBox>
    );
  });
  // Render the card social media icons
  const renderSocial =
    social &&
    social.map(({ link, icon, color }) => (
      <MDBox
        key={color}
        component="a"
        href={link}
        target="_blank"
        rel="noreferrer"
        fontSize={size.lg}
        color={socialMediaColors[color].main}
        pr={1}
        pl={0.5}
        lineHeight={1}
      >
        {icon}
      </MDBox>
    ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      {/* <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {title}
        </MDTypography>
        <MDTypography
          component={Link}
          to={action.route}
          variant="body2"
          color="secondary"
        >
          <Tooltip title={action.tooltip} placement="top">
            <Icon>edit</Icon>
          </Tooltip>
        </MDTypography>
      </MDBox> */}
      <MDBox px={2}>
        {title && description && (
          <>
            <MDBox mb={2} lineHeight={1}>
              <MDTypography variant="button" color="text" fontWeight="light">
                {description}
              </MDTypography>
            </MDBox>

            <MDBox opacity={0.3}>
              <Divider />
            </MDBox>
          </>
        )}



        <MDBox>
          {renderItems}
          {social && (
            <MDBox display="flex" py={1} pr={2}>
              <MDTypography
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
              >
                social: &nbsp;
              </MDTypography>
              {renderSocial}
            </MDBox>
          )}
        </MDBox>
      </MDBox>
      <ConfirmationDialogComponent
          open={showUpdateConfirmation}
          handleClose={() => setShowUpdateConfirmation(false)}
          title="Confirm Update"
          message="Are you sure you want to update your profile?"
          confirmButtonText="Update"
          handleConfirm={() => handleUpdateProfileConfirm()}
        />
        <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}>


      <CircularProgress color="inherit" />

      </Backdrop>
    </Card>
  );
}

// Setting default props for the ProfileInfoCard
ProfileInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  social: PropTypes.arrayOf(PropTypes.object),
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default ProfileInfoCard;
