/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { login } from "actions/userActions";
// Image
import bgImage from "assets/images/onboarding.jpg";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDComponents/MDBox";
import MDButton from "components/MDComponents/MDButton";
import MDInput from "components/MDComponents/MDInput";
import MDTypography from "components/MDComponents/MDTypography";
// formik components
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
// Authentication layout components
import IllustrationLayout from "screens/Auth/components/IllustrationLayout";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import * as yup from "yup";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

function SignInScreen({ location }) {
  //temporarily throw error
  const dispatch = useDispatch();

  // get userLogin from the redux store with useSelector
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const { from } = location.state || { from: { pathname: "/" } };
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      
      // DISPATCH LOGIN
      dispatch(login(values.email, values.password));
    },
  });

  useEffect(() => {
    if (userInfo) {
      navigate(from);
    }
  }, [navigate, userInfo, from]);
  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your email and password to sign in"
      illustration={bgImage}
    >
      {error && (
        <Grid container justifyContent="center">
          <MDBox m={0.75} mb={5}>
            <Alert severity="error" sx={{ width: "100%" }}>
              {error}
            </Alert>
          </MDBox>
        </Grid>
      )}
      <FormikProvider value={formik}>
        <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
          <MDBox mb={2}>
            <Field
              as={MDInput}
              type="email"
              label="Email"
              name="email"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
            <MDBox mt={0.75}>
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
              >
                <ErrorMessage name="email" />
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox mb={2}>
            <Field
              as={MDInput}
              type="password"
              label="Password"
              name="password"
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={
                formik.touched.password && Boolean(formik.errors.password)
              }
            />
            <MDBox mt={0.75}>
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
              >
                <ErrorMessage name="password" />
              </MDTypography>
            </MDBox>
          </MDBox>

          <MDBox mt={4} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              size="large"
              fullWidth
              type="submit"
              sx={{ postion: "relative" }}
            >
              sign in
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "grey",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </MDButton>
          </MDBox>
          {/* <MDBox mt={3} textAlign="center">
            <MDTypography
              component={Link}
              to="/forgot-password"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Forgot password?
            </MDTypography>
          </MDBox> */}
          <MDBox mt={3} textAlign="center">
            <MDTypography
              variant="small"
              color="black"
              fontWeight="medium"
              textGradient
            >
              do not have an account?&nbsp;
            </MDTypography>
            <MDTypography
              component={Link}
              to="/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign Up
            </MDTypography>
          </MDBox>
        </MDBox>
      </FormikProvider>
    </IllustrationLayout>
  );
}

SignInScreen.propTypes = {
  location: PropTypes.string,
};

SignInScreen.defaultProps = {
  location: "",
};

export default SignInScreen;
