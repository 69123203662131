// imports
import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";
const ReviewRecordDetails = ({ sectionType, feedbackTypes }) => {
  const formatFeedbackType = (key) => {
    // Split the key by underscores and capitalize each word
    const words = key
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    // Join the words with spaces and return the formatted string
    return words.join(" ");
  };

  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: "#F4F6F8",
        borderRadius: "0 0 16px 16px",
        mt: 2,
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontWeight: "400",
          mr: 1,
          display: "inline-flex",
          mb: 0,
        }}
      >
        Submitted Section Type: {sectionType}
      </Typography>

      {Object.entries(feedbackTypes).some(([_, value]) => value) ? (
        <>
          <Typography variant="body2" sx={{ fontWeight: "400", mt: 1 }}>
            Selected Feedback Types:
          </Typography>
          <Grid container spacing={1}>
            {Object.entries(feedbackTypes).map(([key, value]) =>
              value ? (
                <Grid item key={key}>
                  <Chip
                    label={formatFeedbackType(key)}
                    color="secondary"
                    variant="filled"
                    sx={{
                      borderRadius: "16px",
                      fontWeight: "400",
                      color: "#FFF",
                      bgcolor: "#5582BA",

                      mt: 1,
                    }}
                  />
                </Grid>
              ) : null
            )}
          </Grid>
        </>
      ) : null}
    </Box>
  );
};

ReviewRecordDetails.propTypes = {
  sectionType: PropTypes.string.isRequired,
  feedbackTypes: PropTypes.objectOf(PropTypes.bool).isRequired,
};

export default ReviewRecordDetails;
