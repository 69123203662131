//imports
import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Avatar, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CustomAvatar from "components/CustomComponents/CustomAvatar";

import "styles/Dialog.module.css";

function ChatBubble({
  backgroundColor,
  avatarContent,
  avatarBgColor,
  text,
  details,
  hasCopyButton,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const chatBubbleTailLocation = {
    top: isMobile ? "-6px" : 0,
    left: isMobile ? "1px" : "-7px",
    transform: isMobile ? "rotate(230deg)" : "rotate(45deg)",
  };

  const handleCopyFeedback = () => {
    // Copy the feedback to the clipboard
    navigator.clipboard.writeText(text);
  };

  return (
    <Grid
      container
      spacing={2}
      direction={isMobile ? "column" : "row"}
      alignItems="flex-start"
      sx={
        {
          // flexWrap: "nowrap", // Prevent the container from wrapping in the mobile viewport
        }
      }
    >
      <Grid item>
        <CustomAvatar
          avatarContent={avatarContent}
          avatarBgColor={avatarBgColor}
        />
      </Grid>
      <Grid item xs>
        <Box
          bgcolor={backgroundColor}
          p={2}
          borderRadius={4}
          sx={{
            whiteSpace: "pre-wrap", // Preserve line breaks

            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px",
            position: "relative",
            // paddingLeft: "20px",

            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            zIndex: 2,
          }}
        >
          <Grid container alignItems="flex-start" justifyContent="flex-end">
            <Grid item xs>
              <Grid container spacing={2}>
                <Grid item xs>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "400",
                      color: "#000",
                    }}
                  >
                    {text}
                    {/* {"\n"} */}
                    {details}

                    {/* {isMobile && (
                        <Box
                          sx={{
                            position: "absolute",
                            width: "20px",
                            height: "20px",
                            bgcolor: "#000000",
                            top: "-7px",
                            left: 0,
                            clipPath: "polygon(0% 0%, 100% 0%, 50% 100%)",
                            transform: "rotate(-45deg)",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                            zIndex: 1,
                          }}
                        />
                      )} */}

                    <Box
                      sx={{
                        position: "absolute",
                        width: "20px",
                        height: "20px",
                        bgcolor: backgroundColor,
                        clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                        zIndex: 1,

                        // top: 0,
                        // left: "-7px",
                        // transform: "rotate(45deg)",
                        ...chatBubbleTailLocation,
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* if hasCopyButton is true then render copy button */}
            {hasCopyButton && (
              <Grid item>
                <IconButton
                  onClick={handleCopyFeedback}
                  aria-label="Copy feedback"
                  sx={{ p: 0, m: 0, fontSize: "18px" }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
export default ChatBubble;
