/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDComponents/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import ProfileInfoCard from "components/Cards/InfoCards/ProfileInfoCard";

// Overview page components
import Header from "screens/Profile/components/Header";
import { titleCase } from "utils/userProfileHelpers";
import axiosInstance from "config/axios";

import { getInitials } from "utils/userProfileHelpers";
import { useEffect, useState } from "react";

function UserProfileScreen() {
  const [userFullName, setUserFullName] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const [userInitials, setUserInitials] = useState("");
  // const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    async function fetchUserProfile() {
      try {
        const response = await axiosInstance.get("/api/user/profile/");
        const { first_name, last_name } = response.data;
        // setUserFullName(titleCase(first_name + " " + last_name));
        const userInitials = getInitials(first_name, last_name);
        setUserInitials(userInitials);

        setUserProfile({
          fullName: titleCase(first_name + " " + last_name),
          ...response.data,
        });
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    }

    fetchUserProfile();
  }, []);
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  //function to update user profile with PATCH method
  const updateUserProfile = async (data) => {
    // await sleep(2000);
    try {
      const response = await axiosInstance.patch("/api/user/profile/", data);
      console.log(response);
      // console.log(data.hasOwnProperty("openai_api_key"))
      if (data.hasOwnProperty("openai_api_key")&&data.openai_api_key.length>=51){
        const api_key=data.openai_api_key
        data.openai_api_key = api_key.substring(0,3)+"..."+api_key.substring(api_key.length-4,api_key.length)
      }
      setUserProfile((currentProfile) =>( { ...currentProfile, ...data}));

    } catch (error) {
      console.error("Error updating user profile:", error);
    }
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header userFullName={userProfile.fullName} userInitials={userInitials}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={10} sx={{ display: "flex" }}>
              <ProfileInfoCard
                title="profile information"
                // description="Director of the Research and Initiatives Center at Prince Sultan University, Full Professor | Drone Expert | Cloud Robotics | Artificial Intelligence"
                info={{
                  fullName: userProfile.fullName,
                  // mobile: "0531478172",
                  email: userProfile.email,
                  location: userProfile.country,
                  openai_api_key: userProfile.openai_api_key,
                }}
                // social={[
                //   {
                //     link: "https://www.linkedin.com/in/anis-koubaa-84aa4344/",
                //     icon: <LinkedInIcon />,
                //     color: "facebook",
                //   },
                // ]}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
                updateUserProfile={updateUserProfile}
              />
              {/* <Divider orientation="vertical" sx={{ mx: 0 }} /> */}
            </Grid>
            <Grid item xs={12} xl={1}>
              {/* <ProfilesList title="conversations" profiles={profilesListData} shadow={false} /> */}
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default UserProfileScreen;
